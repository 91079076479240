import * as React from "react"
import {createGlobalStyle} from "styled-components";
import {Helmet} from "react-helmet";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #010101;
    margin: 0;
    overflow: hidden;
    font-family: monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
`

// styles
const unknownSpanStyles = {
    color: "#FFFFFFF0",
    fontSize: "40px",
    padding: 20,
}

// markup
const NotFoundPage = () => {
    return (
        <main>
            <Helmet>
                <meta charSet={'utf-8'}/>
                <html lang={'en'}/>
                <title>UNKNOWN</title>
            </Helmet>
            <GlobalStyle/>
            <span style={unknownSpanStyles}>UNKNOWN</span>
        </main>
    )
}

export default NotFoundPage
